import styled from "styled-components";
import Image from "next/image";
import { ClickableColumn } from "../base_styles";
import { colours } from "@styles/theme";
import { buildImageUrl, getPrice } from "@utils/utils";
import { Currency } from "@customTypes/currency";
import { Product } from "@customTypes/product";
import { BREAKPOINTS } from "@styles/breakpoints";
import { ColourPicker } from "@components/colour_picker";
import PriceDiscountBlock from "@components/price_discount_block";
import { Colour } from "@customTypes/colour";

const Wrapper = styled(ClickableColumn)`
  cursor: pointer;
  padding: 8px;
  align-items: flex-start;

  :active {
    opacity: 0.8;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 140%;
`;

const ColourPickerWrapper = styled.div`
  margin-top: 8px;
`;

const Title = styled.h2`
  color: ${colours.black};
  margin-top: 8px;
  font-size: 16px;
  text-overflow: ellipsis;
  width: calc(100%);
  overflow: hidden;
`;

const DiscountTitle = styled.p`
  color: ${colours.darkText};
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  margin-top: 6px;

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    font-size: 12px;
  }
`;

const DiscountValue = styled.span`
  color: ${colours.green};
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  margin-top: 2px;

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    font-size: 12px;
  }
`;

const PriceWrapper = styled.div`
  margin-top: 10px;
`;

const A = styled.a`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;

const ADiv = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

type Props = {
  product: Product;
  activeCurrency: Currency;
  onClick: (id: string, colour: Colour) => void;
};

const ProductItem = ({ product, activeCurrency, onClick }: Props) => {
  const onItemClick = () => {
    onClick(product.id, Colour.white);
  };

  const discount = getPrice({
    ethValue: product.discountedPriceETH,
    gbpValue: product.discountedPriceGBP,
    usdValue: product.discountedPriceUSD,
    currency: activeCurrency,
  });
  const price = getPrice({
    ethValue: product.priceETH,
    gbpValue: product.priceGBP,
    usdValue: product.priceUSD,
    currency: activeCurrency,
  });

  const hasDiscount = product.eligibleForDiscount;

  const onColourClick = (colour: Colour) => {
    onClick(product.id, colour);
  };

  return (
    <Wrapper onClick={onItemClick}>
      <ImageWrapper>
        <A>
          <ADiv>
            <Image
              priority
              src={buildImageUrl(product.image)}
              fill
              style={{ objectFit: "cover" }}
              alt=""
              sizes="100%"
            />
          </ADiv>
        </A>
      </ImageWrapper>
      <ColourPickerWrapper>
        <ColourPicker
          colours={product.availableColours}
          onClick={onColourClick}
        />
      </ColourPickerWrapper>
      <Title>{product.name}</Title>
      {hasDiscount && (
        <DiscountTitle>
          Available to claim with{" "}
          <DiscountValue>{product.discountPercentage}% </DiscountValue>
          discount
        </DiscountTitle>
      )}
      <PriceWrapper>
        <PriceDiscountBlock
          black
          price={price}
          discount={discount}
          showDiscount={false}
          activeCurrency={activeCurrency}
          small
        />
      </PriceWrapper>
    </Wrapper>
  );
};
export { ProductItem };
