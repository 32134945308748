import { Row } from "@components/base_styles";
import { Currency } from "@customTypes/currency";
import { BREAKPOINTS } from "@styles/breakpoints";
import { colours } from "@styles/theme";
import { getFormattedPrice } from "@utils/utils";
import styled from "styled-components";

const PriceWrapper = styled(Row)`
  margin-right: 20px;
`;

interface PriceInterface {
  strike?: boolean;
  small?: boolean;
  black?: boolean;
}
const Price = styled.p<PriceInterface>`
  text-decoration: ${(props) => (props.strike ? "line-through" : "none")};
  color: ${(props) => (props.black ? colours.black : colours.darkText)};
  font-size: ${(props) => (props.small ? "14px" : "16px")};

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    font-size: 14px;
  }
`;
interface DiscountedPriceInterface {
  small?: boolean;
}
const DiscountedPrice = styled(Price)<DiscountedPriceInterface>`
  color: ${colours.green};
  margin-left: 12px;
  font-size: ${(props) => (props.small ? "14px" : "16px")};
`;

type Props = {
  price: number;
  discount: number;
  showDiscount: boolean;
  small?: boolean;
  activeCurrency: Currency;
  black?: boolean;
};
const PriceDiscountBlock = ({
  price,
  discount,
  showDiscount,
  small,
  activeCurrency,
  black = false,
}: Props) => {
  return (
    <PriceWrapper>
      <Price strike={!!showDiscount} small={small} black={black}>
        {getFormattedPrice(price, activeCurrency)}
      </Price>
      {!!showDiscount && (
        <DiscountedPrice small={small}>
          {getFormattedPrice(discount, activeCurrency)}
        </DiscountedPrice>
      )}
    </PriceWrapper>
  );
};

export default PriceDiscountBlock;
