import { Row } from "@components/base_styles";
import { Colour, colourKeyMap } from "@customTypes/colour";
import { colours } from "@styles/theme";
import styled from "styled-components";
import DiscountIcon from "../../public/discount_icon.svg";
import Image from "next/image";

const Wrapper = styled(Row)``;

interface SelectedInterface {
  isSelected: boolean;
  clickable: boolean;
  hasDiscount?: boolean;
}
const ColourWrapper = styled.div<SelectedInterface>`
  cursor: ${(props) => (props.clickable ? "pointer" : "auto")};
  padding: ${(props) => (props.hasDiscount ? "0px" : "1px")};
  border: ${(props) =>
    props.isSelected ? `2px solid ${colours.black}` : `2px solid transparent`};
  ${(props) => !props.clickable && `border: none`};
  ${(props) => !props.clickable && `margin-right: 8px`};
  margin-right: 8px;
  position: relative;
`;

interface ColourInterface {
  isSelected: boolean;
  colour: string;
  hasBorder: boolean;
}
const ColourContainer = styled.div<ColourInterface>`
  border: ${(props) =>
    props.hasBorder
      ? `1px solid ${colours.lightGrey}`
      : `1px solid transparent`};
  height: 20px;
  width: 20px;
  background-color: ${(props) => props.colour};
`;

const DiscountIndicator = styled.div`
  height: 20px;
  width: 20px;
  position: absolute;
  top: -9px;
  right: -8px;

  border-radius: 10px;
  padding-top: 1px;

  font-size: 8px;
  color: ${colours.black};
  text-align: center;
  font-weight: 700;
`;

type Props = {
  colours: Colour[];
  selectedColour?: Colour;
  discountColours?: Colour[];
  onClick?: (colour: Colour) => void;
};

const ColourPicker = ({
  colours,
  selectedColour,
  onClick,
  discountColours,
}: Props) => {
  return (
    <Wrapper>
      {colours.map((colour) => {
        const onColourClick = (
          e: React.MouseEvent<HTMLDivElement, MouseEvent>
        ) => {
          e.stopPropagation();
          onClick?.(colour);
        };

        const hasDiscount = discountColours?.includes(colour);
        return (
          <ColourWrapper
            key={`colour-picker-${colour}`}
            isSelected={colour === selectedColour}
            onClick={onColourClick}
            clickable={!!onClick}
          >
            <ColourContainer
              isSelected={colour === selectedColour}
              colour={colourKeyMap[colour]}
              hasBorder={colour === Colour.white}
            />
            {hasDiscount && (
              <DiscountIndicator>
                <Image src={DiscountIcon} height={20} width={20} alt="" />
              </DiscountIndicator>
            )}
          </ColourWrapper>
        );
      })}
    </Wrapper>
  );
};
export { ColourPicker };
