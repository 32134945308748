import styled from "styled-components";
import Image from "next/image";
import { colours } from "@styles/theme";
import { useIsMobileWidth } from "@hooks/use_is_mobile_width";
import { BREAKPOINTS } from "@styles/breakpoints";
import { Clickable, Column, Row } from "@components/base_styles";
import { HAPE_PRIME_COLLETION_URL, SCROLL_THUMB_WIDTH } from "@utils/constants";
import FullPageImage from "@components/full_page_image";
import DownIcon from "../../public/down_icon.svg";
import { useRef } from "react";

const IMAGE_HORIZONTAL_PADDING_DESKTOP = 0;
const IMAGE_HORIZONTAL_PADDING_MOBILE = 0;

const LANDSCAPE_ASPECT_RATIO = 900 / 1600;
const PORTRAIT_ASPECT_RATIO = 1600 / 900;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: ${colours.white};
`;

const ImageContent = styled(Column)`
  width: 100%;
  padding: ${`${IMAGE_HORIZONTAL_PADDING_DESKTOP}px ${IMAGE_HORIZONTAL_PADDING_DESKTOP}px`};
  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    padding: ${`${IMAGE_HORIZONTAL_PADDING_MOBILE}px ${IMAGE_HORIZONTAL_PADDING_MOBILE}px`};
  }
`;

const ImageRow = styled(Row)`
  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    display: block;
  }
`;

interface ImageInterface {
  imageHeight: number;
  maxWidth?: number;
  flex?: boolean;
}

const ImageContainer = styled.div<ImageInterface>`
  position: relative;
  height: ${(props) => `${props.imageHeight}px`};
  ${(props) => `${props.maxWidth && `max-width: ${props.maxWidth}px`}`};
  ${(props) => `${props.flex && `flex: 1`}`};

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    flex: none;
  }
`;

const ImageContenxtContainer = styled.div``;

const TextBlock = styled.div`
  position: absolute;
  top: 30%;
  margin-left: 52px;

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    position: absolute;
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    right: 0;
    top: 15%;
    padding: 16px;

    @media (orientation: landscape) {
      top: 5%;
    }
  }
`;

const TitleText = styled.h1`
  color: ${colours.white};
  font-weight: 600;
  max-width: 1000px;
  font-size: 42px;
  white-space: pre-wrap;
  line-height: 45px;
  margin-bottom: 12px;

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    word-wrap: break-word;
    font-size: 50px;
    line-height: 50px;
    margin-bottom: 10px;

    @media (orientation: landscape) {
      font-size: 30px;
      line-height: 32px;
    }
  }
`;

const Title2Text = styled.h1`
  color: ${colours.white};
  font-weight: 300;
  max-width: 1000px;
  font-size: 38px;
  white-space: pre-wrap;
  line-height: 40px;

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    font-weight: 200;
    word-wrap: break-word;
    font-size: 28px;
    line-height: 35px;

    @media (orientation: landscape) {
      font-size: 24px;
      line-height: 26px;
    }
  }
`;

const SubtitleText = styled(TitleText)`
  color: ${colours.white};
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  margin-top: 30px;
  max-width: 600px;
  white-space: pre-wrap;

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    max-width: none;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0px;

    @media (orientation: landscape) {
      line-height: 20px;
    }
  }
`;

const ButtonWrapper = styled.div`
  max-width: 300px;
  margin-top: 22px;
  display: flex;

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    max-width: none;
    margin-top: 12px;

    @media (orientation: landscape) {
      max-width: 200px;
    }
  }
`;

const CollectionButton = styled.a`
  background-color: ${colours.black};
  color: ${colours.white};
  border: 1px solid ${colours.black};
  border-radius: 6px;
  padding: 16px 12px;
  width: 100%;
  justify-content: center;
  font-size: 12px;
  font-weight: 300;
  width: 100%;
  text-align: center;
  transition: 0.1s ease-in;

  :active {
    transform: translateY(1px) scale(0.99);
    opacity: 0.8;
  }

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    @media (orientation: landscape) {
      padding: 12px 12px;
    }
  }
`;

const FooterWrapper = styled(Clickable)`
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-left: 72px;
  bottom: 84px;
  justify-content: center;
  align-items: center;

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    bottom: 15px;
    width: 100%;
    margin-left: 0px;
    align-items: center;
  }
`;

const FooterText = styled.p`
  font-size: 14px;
  font-weight: 100;
  color: ${colours.white};
  margin-bottom: 8px;

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    @media (orientation: landscape) {
      font-size: 12px;
    }
  }
`;

const FooterImageContainer = styled.div`
  position: relative;
  height: 24px;
  width: 24px;

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    @media (orientation: landscape) {
      height: 20px;
      width: 20px;
    }
  }
`;

const title1 = "Thanks for stopping by!";
const title2 = "Our apparel claim window has closed.";
const subtitle =
  "Missed out? No worries!\n\nYou can still immerse yourself in our vibrant community! Explore our unique Hapes and find your perfect match.";

const StandbyPage = () => {
  const secondImageRef = useRef<HTMLDivElement>();
  const { isMobileWidth, screenWidth } = useIsMobileWidth();

  const imageContentWidth = screenWidth - SCROLL_THUMB_WIDTH;

  const getImageHeight = (
    aspectRatio: number,
    isHalfScreen: boolean = false
  ) => {
    if (isHalfScreen) {
      return (imageContentWidth / 2) * aspectRatio;
    }
    return imageContentWidth * aspectRatio;
  };

  const onExploreClick = () => {
    secondImageRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Main>
      <ImageContent>
        <FullPageImage
          imageUrl={
            isMobileWidth
              ? "/look_book_image_6.jpg"
              : "/standby_mode_image_desktop.jpg"
          }
          imagePosition={isMobileWidth ? "30% 8%" : "50% 15%"}
        >
          <TextBlock>
            <TitleText>{title1}</TitleText>
            <Title2Text>{title2}</Title2Text>
            <SubtitleText>{subtitle}</SubtitleText>
            <ButtonWrapper>
              <CollectionButton href={HAPE_PRIME_COLLETION_URL} target="_blank">
                {"View Collection"}
              </CollectionButton>
            </ButtonWrapper>
          </TextBlock>
          <FooterWrapper onClick={onExploreClick}>
            <FooterText>explore</FooterText>
            <FooterImageContainer>
              <Image
                priority
                src={DownIcon}
                fill
                style={{ objectFit: "contain" }}
                alt=""
              />
            </FooterImageContainer>
          </FooterWrapper>
        </FullPageImage>
        <ImageContenxtContainer
          // @ts-ignore
          ref={secondImageRef}
        >
          <ImageContainer imageHeight={getImageHeight(LANDSCAPE_ASPECT_RATIO)}>
            <Image
              priority
              src={"/look_book_image_7.jpg"}
              fill
              style={{ objectFit: "contain" }}
              alt=""
            />
          </ImageContainer>
        </ImageContenxtContainer>
        <ImageRow>
          <ImageContainer
            imageHeight={getImageHeight(PORTRAIT_ASPECT_RATIO, !isMobileWidth)}
            flex
          >
            <Image
              priority
              src={"/look_book_image_8.jpg"}
              fill
              style={{ objectFit: "contain" }}
              alt=""
            />
          </ImageContainer>
          <ImageContainer
            flex
            imageHeight={getImageHeight(PORTRAIT_ASPECT_RATIO, !isMobileWidth)}
          >
            <Image
              priority
              src={"/look_book_image_9.jpg"}
              fill
              style={{ objectFit: "contain" }}
              alt=""
            />
          </ImageContainer>
        </ImageRow>
        <ImageContenxtContainer>
          <ImageContainer imageHeight={getImageHeight(LANDSCAPE_ASPECT_RATIO)}>
            <Image
              priority
              src={"/look_book_image_10.jpg"}
              fill
              style={{ objectFit: "contain" }}
              alt=""
            />
          </ImageContainer>
        </ImageContenxtContainer>
        <ImageContenxtContainer>
          <ImageContainer imageHeight={getImageHeight(LANDSCAPE_ASPECT_RATIO)}>
            <Image
              priority
              src={"/look_book_image_1.jpg"}
              fill
              style={{ objectFit: "contain" }}
              alt=""
            />
          </ImageContainer>
        </ImageContenxtContainer>
        <ImageRow>
          <ImageContainer
            flex
            imageHeight={getImageHeight(PORTRAIT_ASPECT_RATIO, !isMobileWidth)}
          >
            <Image
              priority
              src={"/look_book_image_2.jpg"}
              fill
              style={{ objectFit: "contain" }}
              alt=""
            />
          </ImageContainer>
          <ImageContainer
            flex
            imageHeight={getImageHeight(PORTRAIT_ASPECT_RATIO, !isMobileWidth)}
          >
            <Image
              priority
              src={"/look_book_image_3.jpg"}
              fill
              style={{ objectFit: "contain" }}
              alt=""
            />
          </ImageContainer>
        </ImageRow>
        <ImageContenxtContainer>
          <ImageContainer imageHeight={getImageHeight(LANDSCAPE_ASPECT_RATIO)}>
            <Image
              priority
              src={"/look_book_image_4.jpg"}
              fill
              style={{ objectFit: "contain" }}
              alt=""
            />
          </ImageContainer>
        </ImageContenxtContainer>
        <ImageContenxtContainer>
          <ImageContainer imageHeight={getImageHeight(LANDSCAPE_ASPECT_RATIO)}>
            <Image
              priority
              src={"/look_book_image_5.jpg"}
              fill
              style={{ objectFit: "contain" }}
              alt=""
            />
          </ImageContainer>
        </ImageContenxtContainer>
        <ImageRow>
          <ImageContainer
            flex
            imageHeight={getImageHeight(PORTRAIT_ASPECT_RATIO, !isMobileWidth)}
          >
            <Image
              src={"/look_book_image_11.jpg"}
              fill
              style={{ objectFit: "contain" }}
              alt=""
            />
          </ImageContainer>
          <ImageContainer
            flex
            imageHeight={getImageHeight(PORTRAIT_ASPECT_RATIO, !isMobileWidth)}
          >
            <Image
              src={"/look_book_image_12.jpg"}
              fill
              style={{ objectFit: "contain" }}
              alt=""
            />
          </ImageContainer>
        </ImageRow>
        <ImageContenxtContainer>
          <ImageContainer imageHeight={getImageHeight(LANDSCAPE_ASPECT_RATIO)}>
            <Image
              priority
              src={"/look_book_image_13.jpg"}
              fill
              style={{ objectFit: "contain" }}
              alt=""
            />
          </ImageContainer>
        </ImageContenxtContainer>
        <ImageRow>
          <ImageContainer
            flex
            imageHeight={getImageHeight(PORTRAIT_ASPECT_RATIO, !isMobileWidth)}
          >
            <Image
              priority
              src={"/look_book_image_14.jpg"}
              fill
              style={{ objectFit: "contain" }}
              alt=""
            />
          </ImageContainer>
          <ImageContainer
            flex
            imageHeight={getImageHeight(PORTRAIT_ASPECT_RATIO, !isMobileWidth)}
          >
            <Image
              priority
              src={"/look_book_image_15.jpg"}
              fill
              style={{ objectFit: "contain" }}
              alt=""
            />
          </ImageContainer>
        </ImageRow>
      </ImageContent>
    </Main>
  );
};

export default StandbyPage;
