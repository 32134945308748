import styled from "styled-components";
import Image from "next/image";
import { ClickableRow, Column } from "../base_styles";
import { colours } from "@styles/theme";
import { BREAKPOINTS } from "@styles/breakpoints";
import { useIsMobileWidth } from "@hooks/use_is_mobile_width";

const Wrapper = styled(Column)``;

const ItemWrapper = styled.div``;

const ItemContainer = styled(Column)``;

const ItemTitleRow = styled(ClickableRow)`
  padding-right: 30px;
`;

interface Small {
  small: boolean;
}
const ItemTitle = styled.h2<Small>`
  color: ${colours.black};
  margin-right: auto;
  font-size: ${(props) => (props.small ? "14px" : "20px")};

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    font-size: ${(props) => (props.small ? "14px" : "18px")};
  }
`;

interface ItemBodyInterface {
  isExpanded: boolean;
  small: boolean;
}
const ItemBody = styled.div<ItemBodyInterface>`
  max-height: ${(props) => (props.isExpanded ? "1000px" : "0px")};
  transition: 0.3s ease-in-out;
  overflow: hidden;
  font-size: ${(props) => (props.small ? "12px" : "16px")};

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    max-height: ${(props) => (props.isExpanded ? "1000px" : "0px")};
    font-size: ${(props) => (props.small ? "12px" : "14px")};
  }
`;

interface DividerInterface {
  light: boolean;
}
const Divider = styled.div<DividerInterface>`
  height: 1px;
  width: 100%;
  background-color: ${(props) =>
    props.light ? `${colours.lightGrey}` : "grey"};
  margin: 30px 0;
`;

const ItemBodyText = styled.p`
  color: ${colours.black};
  padding: 22px 0;
  white-space: pre-wrap;
  user-select: text;
`;

const Span = styled.span`
  color: ${colours.black};
  white-space: pre-wrap;
`;

const Link = styled.a`
  color: ${colours.darkText};
  text-decoration: underline;
`;

export type AccordionItem = {
  id: string;
  title: string;
  body: string;
  isOpen: boolean;
};

export enum AccoridionSize {
  small,
  regular,
}

type Props = {
  items: AccordionItem[];
  onItemClick: (index: number) => void;
  size?: AccoridionSize;
  lightDivider?: boolean;
};

const linkDelimeter1 = "{{";
const linkDelimeter2 = "}}";
const linkTextUrlDelimeter = "=";

const Accordion = ({
  items,
  onItemClick,
  size = AccoridionSize.regular,
  lightDivider = false,
}: Props) => {
  const { isMobileWidth } = useIsMobileWidth();
  const isSmall = size === AccoridionSize.small;
  const iconSize = isSmall ? 10 : 14;

  const renderBodyText = (text: String) => {
    const bodySplitText = text.split(linkDelimeter1);
    if (bodySplitText.length < 2) {
      return <ItemBodyText>{text}</ItemBodyText>;
    }

    return (
      <ItemBodyText>
        {bodySplitText.map((linkText) => {
          const postLinkSplitText = linkText.split(linkDelimeter2);

          if (postLinkSplitText.length == 1) {
            return postLinkSplitText[0];
          }

          return postLinkSplitText.map((postLinkItem, index) => {
            if (index === 0) {
              const linkUrlText = postLinkItem.trim();
              const linkUrlSplit = linkUrlText.split(linkTextUrlDelimeter);

              const doubleLinkText = linkUrlSplit[1];
              const linkList = doubleLinkText.split("|");

              if (linkList.length < 2) {
                return (
                  <Link
                    key={`accorion-link-${index}-${linkList[0]}`}
                    href={linkList[0]}
                    target="_blank"
                  >
                    <Span style={{ color: `${colours.darkText}` }}>
                      {linkUrlSplit[0]}
                    </Span>
                  </Link>
                );
              }
              const desktopLink = linkList[0];
              const mobileLink = linkList[1];
              const link = isMobileWidth ? mobileLink : desktopLink;

              return (
                <Link
                  key={`accorion-link-${index}-${link}`}
                  href={link}
                  target="_blank"
                >
                  <Span style={{ color: `${colours.darkText}` }}>
                    {linkUrlSplit[0]}
                  </Span>
                </Link>
              );
            }
            // Handles not showing a space after a link and before a full stop
            if (postLinkItem.startsWith(".")) {
              return (
                <Span key={`accorion-link-${index}-${postLinkItem}`}>
                  {postLinkItem.trim()}{" "}
                </Span>
              );
            }
            return (
              <Span key={`accorion-link-${index}-${postLinkItem}`}>
                {" "}
                {postLinkItem.trim()}{" "}
              </Span>
            );
          });
        })}
      </ItemBodyText>
    );
  };

  return (
    <Wrapper>
      {items.map((item, index) => {
        return (
          <ItemWrapper key={item.id}>
            {index === 0 && <Divider light={lightDivider} />}
            <ItemContainer>
              <ItemTitleRow onClick={() => onItemClick(index)}>
                <ItemTitle small={isSmall}>{item.title}</ItemTitle>
                <Image
                  src={item.isOpen ? "../minus_icon.svg" : "../plus_icon.svg"}
                  height={iconSize}
                  width={iconSize}
                  style={{ objectFit: "cover" }}
                  alt=""
                />
              </ItemTitleRow>
              <ItemBody isExpanded={item.isOpen} small={isSmall}>
                {renderBodyText(item.body)}
              </ItemBody>
            </ItemContainer>
            <Divider light={lightDivider} />
          </ItemWrapper>
        );
      })}
    </Wrapper>
  );
};

export { Accordion };
