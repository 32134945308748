import styled from "styled-components";
import Image from "next/image";
import { BREAKPOINTS } from "@styles/breakpoints";
import { Clickable, Row } from "@components/base_styles";
import { useIsMobileWidth } from "@utils/hooks/use_is_mobile_width";
import { colours } from "@styles/theme";
import { ProductList } from "@components/product_list";
import { FAQ } from "@components/faq";
import { useRouter } from "next/router";
import { Routes } from "@router/routes";
import { useContext } from "react";
import { ContentUseCase } from "@use_case/content_use_case";
import { LandingData } from "@customTypes/landing_data";
import { FAQItem } from "@customTypes/faq_item";
import { PageData } from "@customTypes/result";
import { Button } from "@components/button";
import PageLoader from "@components/page_loader";
import { RetryView } from "@components/retry_view";
import { StoreContext } from "@providers/store_provider";
import { HomeImage } from "@components/home_image";
import { isStandbyMode } from "@utils/constants";
import StandbyPage from "@components/standby_page";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: ${colours.white};
`;

const CollageImageWrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  margin: 52px 0;
`;

const LookBookButtonWrapper = styled.div`
  width: 100%;
  max-width: 300px;
  margin-bottom: 52px;

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    max-width: none;
    padding: 0 12px;
  }
`;

const LookBookButton = styled(Button)`
  padding: 20px;
`;

const FirstProductListMargins = styled.div`
  margin-top: 82px;
  margin-bottom: 82px;

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    margin-top: 42px;
    margin-bottom: 0px;
  }
`;

const ProductListWrapper = styled.div`
  width: 100%;
  margin: 0 24px;
  padding: 0 32px;

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    padding: 0;
  }
`;

const FAQWrapper = styled.div`
  width: 100%;
  padding: 0 32px;
  margin-top: 62px;

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    padding: 0 12px;
  }
`;

const ProductRow = styled(Row)`
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0px 8px;
  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    padding: 0 12px;
  }
`;

const ProductTitle = styled.h2`
  padding: 0;
  margin: 0;
  font-size: 22px;
  color: ${colours.black};
`;

const ViewAllProductButton = styled(Clickable)`
  color: ${colours.black};
  text-decoration: underline;
  font-size: 14px;
`;

const LoadingContainer = styled.div`
  margin-top: 100px;
`;

const COLLAGE_IMAGE_ASPECT_RATIO_DESKTOP = 1080 / 1920;
const COLLAGE_IMAGE_ASPECT_RATIO_MOBILE = 1600 / 900;

const FIRST_ROW_ITEM_COUNT_DESKTOP = 3;
const FIRST_ROW_ITEM_COUNT_MOBILE = 2;
const SECOND_ROW_ITEM_COUNT_DESKTOP = 9;
const SECOND_ROW_ITEM_COUNT_MOBILE = 6;

type Props = {
  landingPageData: PageData<LandingData>;
  faqData: PageData<FAQItem[]>;
};

export default function Home({ landingPageData, faqData }: Props) {
  const { isMobileWidth } = useIsMobileWidth();
  const router = useRouter();
  const {
    products,
    isLoading: isLoadingProducts,
    hasError: hasProductError,
    fetchProducts,
  } = useContext(StoreContext);

  const firstRowItemCount = isMobileWidth
    ? FIRST_ROW_ITEM_COUNT_MOBILE
    : FIRST_ROW_ITEM_COUNT_DESKTOP;

  const SecondRowItemCount = isMobileWidth
    ? SECOND_ROW_ITEM_COUNT_MOBILE
    : SECOND_ROW_ITEM_COUNT_DESKTOP;

  const first3Products = products.slice(0, firstRowItemCount);
  const products4To9 = products.slice(firstRowItemCount, SecondRowItemCount);

  const onLookBookClick = () => {
    router.push(Routes.lookBook);
  };

  const collageHeight =
    window.innerWidth *
    (isMobileWidth
      ? COLLAGE_IMAGE_ASPECT_RATIO_MOBILE
      : COLLAGE_IMAGE_ASPECT_RATIO_DESKTOP);

  const pushProductPage = () => {
    router.push(Routes.products);
  };

  if (isStandbyMode) {
    return <StandbyPage />;
  }

  return (
    <Main>
      {!landingPageData.hasError && (
        <HomeImage
          data={landingPageData.data}
          hasError={landingPageData.hasError}
        />
      )}
      <ProductListWrapper>
        <FirstProductListMargins>
          {hasProductError ? (
            <RetryView
              title={`An error occured fetching the products. \nPlease try again`}
              onRetryClick={fetchProducts}
            />
          ) : isLoadingProducts ? (
            <LoadingContainer>
              <PageLoader />
            </LoadingContainer>
          ) : (
            <ProductList products={first3Products} />
          )}
        </FirstProductListMargins>
      </ProductListWrapper>
      <CollageImageWrapper
        style={{
          height: collageHeight,
        }}
      >
        <Image
          priority
          src={
            isMobileWidth
              ? "/look_book_banner_mobile.jpg"
              : "/look_book_banner.jpg"
          }
          fill
          style={{ objectFit: "contain" }}
          alt=""
        />
      </CollageImageWrapper>

      <LookBookButtonWrapper onClick={onLookBookClick}>
        <LookBookButton>Explore Look Book</LookBookButton>
      </LookBookButtonWrapper>

      <ProductListWrapper>
        {products4To9.length > 0 && !isLoadingProducts && (
          <ProductRow>
            <ProductTitle>Products</ProductTitle>
            <ViewAllProductButton onClick={pushProductPage}>
              View all
            </ViewAllProductButton>
          </ProductRow>
        )}
        {hasProductError ? (
          <RetryView
            title={`An error occured fetching the products. \nPlease try again`}
            onRetryClick={fetchProducts}
          />
        ) : isLoadingProducts ? (
          <LoadingContainer>
            <PageLoader />
          </LoadingContainer>
        ) : (
          <ProductList products={products4To9} />
        )}
      </ProductListWrapper>

      {!faqData.hasError && (
        <FAQWrapper>
          <FAQ data={faqData.data} />
        </FAQWrapper>
      )}
    </Main>
  );
}

export async function getStaticProps(): Promise<{ props: Props }> {
  const landingDataRequest = ContentUseCase.getLandingPageData();
  const faqRequest = ContentUseCase.getFAQs();

  let propData: Props = {
    landingPageData: { hasError: true },
    faqData: { hasError: true },
  };

  if (process.env.NEXT_PUBLIC_STANDBY_MODE === "true") {
    return { props: propData };
  }

  const allResponses = await Promise.allSettled([
    landingDataRequest,
    faqRequest,
  ]);

  allResponses.forEach((response, index) => {
    if (index === 0) {
      if (response.status === "fulfilled") {
        if (response.value.ok) {
          propData.landingPageData = {
            hasError: false,
            data: response.value.value as LandingData,
          };
        }
      }
    } else if (index === 1) {
      if (response.status === "fulfilled") {
        if (response.value.ok) {
          propData.faqData = {
            hasError: false,
            data: response.value.value as FAQItem[],
          };
        }
      }
    }
  });

  return { props: propData };
}
