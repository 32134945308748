import { BREAKPOINTS } from "@styles/breakpoints";
import styled from "styled-components";
import { useIsMobileWidth } from "@hooks/use_is_mobile_width";
import { LandingData } from "@customTypes/landing_data";
import { buildImageUrl } from "@utils/utils";
import { colours } from "@styles/theme";
import FullPageImage from "@components/full_page_image";

const TextBlock = styled.div`
  position: absolute;
  top: 40%;
  left: 55%;

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    bottom: 15%;
    left: 18px;
    right: 18px;

    @media (orientation: landscape) {
      top: 20%;
      display: flex;
      flex-direction: column;
      right: 64px;
      left: auto;
    }
  }
`;

const TitleText = styled.h1`
  color: ${colours.white};
  font-weight: 600;
  max-width: 1000px;
  font-size: 62px;
  text-transform: uppercase;
  white-space: pre-wrap;
  line-height: 55px;

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    font-weight: 600;
    word-wrap: break-word;
    font-size: 54px;
    line-height: 52px;

    @media (orientation: landscape) {
      font-size: 42px;
      line-height: 40px;
    }
  }
`;

const SubtitleText = styled(TitleText)`
  color: ${colours.white};
  font-weight: 300;
`;

type Props = {
  data: LandingData;
  hasError: boolean;
};

const HomeImage = ({ data, hasError }: Props) => {
  const { isMobileWidth } = useIsMobileWidth();

  const title = hasError ? "" : data.title;
  const subtitle = hasError ? "" : data.summary;

  const imageUrl = hasError
    ? "/home_main_image.png"
    : buildImageUrl(data.image);

  return (
    <FullPageImage
      imageUrl={imageUrl}
      imagePosition={isMobileWidth ? "30% 50%" : "50% 15%"}
    >
      <TextBlock>
        <TitleText>{title}</TitleText>
        <SubtitleText>{subtitle}</SubtitleText>
      </TextBlock>
    </FullPageImage>
  );
};

export { HomeImage };
