import { BREAKPOINTS } from "@styles/breakpoints";
import styled from "styled-components";
import Image from "next/image";
import { useContext } from "react";
import { MenuContext } from "@providers/menu_provider";
import { ErrorContext } from "@providers/error_provider";
import { useIsMobileWidth } from "@hooks/use_is_mobile_width";
import {
  ERROR_MESSAGE_HEIGHT_DESKTOP,
  ERROR_MESSAGE_HEIGHT_MOBILE,
  HEADER_HEIGHT_MOBILE,
} from "@utils/constants";

interface ImageWrapperInterface {
  headerContainerHeight: number;
}
const ImageWrapper = styled.div<ImageWrapperInterface>`
  display: flex;
  position: relative;

  width: 100%;
  height: ${(props) => `calc(100vh - ${props.headerContainerHeight}px)`};
  max-height: ${(props) =>
    `-webkit-fill-available - ${props.headerContainerHeight}px)`};
  max-height: -webkit-fill-available;

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    height: ${(props) => `calc(100vh - ${props.headerContainerHeight}px)`};
    min-height: 600px;

    @media (orientation: landscape) {
      min-height: 350px;
    }
  }
`;

type Props = {
  children: React.ReactNode;
  imageUrl: string;
  imagePosition: string;
};

const FullPageImage = ({ children, imageUrl, imagePosition }: Props) => {
  const { isMobileWidth } = useIsMobileWidth();
  const { desktopHeaderHeight } = useContext(MenuContext);
  const { menuErrorMessage } = useContext(ErrorContext);

  const menuErrorIsVisible = !!menuErrorMessage;

  const errorMessageHeight = isMobileWidth
    ? ERROR_MESSAGE_HEIGHT_MOBILE
    : ERROR_MESSAGE_HEIGHT_DESKTOP;

  const headerContentHeight = isMobileWidth
    ? HEADER_HEIGHT_MOBILE
    : desktopHeaderHeight;

  const headerContainerHeight =
    headerContentHeight + (menuErrorIsVisible ? errorMessageHeight : 0);

  return (
    <ImageWrapper headerContainerHeight={headerContainerHeight}>
      <Image
        priority
        src={imageUrl}
        alt=""
        fill
        style={{
          objectFit: "cover",
          objectPosition: imagePosition,
        }}
      />
      {children}
    </ImageWrapper>
  );
};

export default FullPageImage;
