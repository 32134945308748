import { Column } from "@components/base_styles";
import { Button } from "@components/button";
import { BREAKPOINTS } from "@styles/breakpoints";
import { colours } from "@styles/theme";
import styled from "styled-components";

const ErrorContainer = styled(Column)`
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 12px;
`;

const ErrorText = styled.h4`
  font-weight: 300;
  font-size: 14px;
  text-align: center;
  color: ${colours.black};
  margin-bottom: 22px;
  white-space: pre-wrap;
`;

const RetryButton = styled(Button)`
  max-width: 300px;

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    max-width: none;
  }
`;

type Props = {
  title: string;
  onRetryClick: () => void;
  buttonTitle?: string;
};

const RetryView = ({ title, onRetryClick, buttonTitle }: Props) => {
  return (
    <ErrorContainer>
      <ErrorText>{title}</ErrorText>
      <RetryButton onClick={onRetryClick}>{buttonTitle ?? "Retry"}</RetryButton>
    </ErrorContainer>
  );
};

export { RetryView };
