import { FAQItem } from "@customTypes/faq_item";
import { BREAKPOINTS } from "@styles/breakpoints";
import { colours } from "@styles/theme";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Accordion, AccordionItem } from "../accordion";
import { Column } from "../base_styles";

const Wrapper = styled(Column)``;

const Title = styled.h1`
  color: ${colours.black};
  margin-bottom: 20px;
  font-weight: 800;
  font-size: 36px;

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    font-size: 22px;
  }
`;
const Subtitle = styled.h3`
  color: ${colours.black};
  margin-bottom: 30px;

  font-size: 18px;

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    font-size: 16px;
    margin-bottom: 20px;
  }
`;

type Props = {
  data: FAQItem[];
};

const FAQ = ({ data }: Props) => {
  const [items, setItems] = useState<AccordionItem[]>([]);

  useEffect(() => {
    if (!!data) {
      const expandableData: AccordionItem[] = data.map((item) => {
        return {
          id: item.id,
          title: item.question,
          body: item.answer,
          isOpen: false,
        };
      });
      setItems(expandableData);
    }
  }, [data]);

  const onAccordionItemClick = (index: number) => {
    const itemCopy = [...items];
    itemCopy[index].isOpen = !itemCopy[index].isOpen;

    setItems([...itemCopy]);
  };

  return (
    <Wrapper>
      <Title>Frequently asked questions</Title>
      <Subtitle>
        Claim a limited edition physical version of your digital wearable from
        our Foundations Box collection.
      </Subtitle>
      <Accordion items={items} onItemClick={onAccordionItemClick} />
    </Wrapper>
  );
};

export { FAQ };
