import { Product } from "@customTypes/product";
import { Routes } from "@router/routes";
import { BREAKPOINTS } from "@styles/breakpoints";
import { useRouter } from "next/router";
import styled from "styled-components";
import { Column } from "../base_styles";
import { ProductItem } from "../product_item";
import { useContext } from "react";
import { CurrencyContext } from "@providers/currency_provider";
import { Colour } from "@customTypes/colour";

const Wrapper = styled(Column)`
  padding: 1px;
  width: 100%;
`;

const ProductWrapper = styled.div`
  width: 100%;
  align-self: center;
`;

const ProductContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 33.33%);
  grid-row-gap: 2.5em;

  @media (max-width: ${BREAKPOINTS.smallDesktopPx}) {
    grid-template-columns: repeat(3, 33.33%);
  }

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    grid-template-columns: repeat(2, 50%);
  }
`;

type Props = {
  products: Product[];
};
const ProductList = ({ products }: Props) => {
  const router = useRouter();
  const { activeCurrency } = useContext(CurrencyContext);

  const onItemClick = (id: string, colour: Colour) => {
    router.push(Routes.productDetails(id, colour.toString()));
  };

  return (
    <Wrapper>
      <ProductWrapper>
        <ProductContainer>
          {products.map((product) => {
            return (
              <ProductItem
                key={product.id}
                product={product}
                onClick={onItemClick}
                activeCurrency={activeCurrency}
              />
            );
          })}
        </ProductContainer>
      </ProductWrapper>
    </Wrapper>
  );
};
export { ProductList };
